import React, { useState, useEffect } from 'react';
import { Button, Row, Col, Card } from 'react-bootstrap';
import { CDBContainer, CDBDataTable, CDBSpinner } from 'cdbreact';
import Base from '../Layout/Base';
import axios from 'axios';

export default function JobList() {
    const [jobs, setJobs] = useState([])
    const [loading, setLoading] = useState(true)
    const [todayCount, setTodayCount] = useState(0)

    function fetchJobList() {
        if (!localStorage.getItem('token')) {
            window.location.href = '/auth/login'
        }
        axios({
            method: 'GET',
            headers: {
                "Authorization": "Bearer " + localStorage.getItem('token')
            },
            url: "/api/results/"
        })
        .then(response => {
            console.log(response.data)
            setJobs(response.data.job_list)
            setTodayCount(response.data.today_job_count)
            setLoading(false)
        })
        .catch(error => {
            console.log(error.response)
            if ( error.response.status === 401 ) {
                alert("Your session has expired. Please log in again.")
                localStorage.removeItem('token')
                localStorage.removeItem('user')
                window.location.href = '/auth/login'
            }
            console.error("Error fetching jobs", error)
            

        })
    }
    useEffect(() => {
        fetchJobList()
    }
    , [])
    const data = () => {
        return {
            columns: [
                {
                    label: 'Job ID',
                    field: 'id',
                    sort : 'asc',
                },
                {
                    label: 'Job Type',
                    field: 'job_type',
                    sort : 'asc',
                },
                {
                    label: 'Status',
                    field: 'status',
                    sort : 'asc',
                },
                {
                    label: 'Last Updated',
                    field: 'last_updated',
                    sort : 'asc',
                },
                {
                    label: 'User',
                    field: 'user_id',
                    sort : 'asc',
                },
                {
                    label: 'View',
                    field: 'view',
                    sort : 'asc',
                }
            ],
            rows: jobs.map(job => ({
                id : job.id,
                job_type : job.job_type,
                status : job.status,
                last_updated : job.last_updated,
                user_id : job.user_id,
                view : <a href={`/jobs/${job.id}`}><Button>View</Button></a>
                   
        }))
        }
    }

    return (
        <Base>
            <Row>
                <Col md={{ span: 2, offset: 10 }}>
                    <Card>
                        <Card.Header style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            Today's Job Count
                        </Card.Header>
                        <Card.Body>
                            <Card.Title style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                {todayCount}/100
                            </Card.Title>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            
            <CDBContainer>
                <CDBSpinner
                        style={{ display: loading ? 'block' : 'none' }}
                        color="info"
                    />
               
                <CDBDataTable
                    striped
                    hover
                    entriesOptions={[10, 20, 50]}
                    entries={10}
                    pagesAmount={4}
                    data={data()}
                    searching={false}
                />
            </CDBContainer>
         
        </Base>
     
    )
}